<script>
"use strict";
import { SnotifyPosition, SnotifyStyle } from "vue-snotify";
import $snotify from "vue-snotify";
export default {
  $snotify,
  methods: {
    displayNotification: function (
      type,
      title = "",
      message = "",
      timeOut = 2000,
      textButton = "Aceptar"
    ) {
      switch (type) {
        case "successGoBack":
          this.goBack();
          setTimeout(() => {
            this.$snotify.create({
              title: title,
              body: message,
              config: {
                timeout: timeOut,
                showProgressBar: true,
                closeOnClick: true,
                position: SnotifyPosition.rightTop,
                type: SnotifyStyle.success,
                buttons: [{ text: textButton, action: null, bold: true }],
              },
            });
          }, 1500);

          break;
        case "warningGoBack":
          this.goBack();
          setTimeout(() => {
            this.$snotify.create({
              title: title,
              body: message,
              config: {
                timeout: timeOut,
                showProgressBar: true,
                closeOnClick: true,
                position: SnotifyPosition.rightTop,
                type: SnotifyStyle.warning,
                buttons: [{ text: textButton, action: null, bold: true }],
              },
            });
          }, 1500);

          break;
        case "success":
          this.$snotify.create({
            title: title,
            body: message,
            config: {
              timeout: timeOut,
              showProgressBar: true,
              closeOnClick: true,
              position: SnotifyPosition.rightTop,
              type: SnotifyStyle.success,
              buttons: [{ text: "Aceptar", action: null, bold: true }],
            },
          });
          break;
        case "error":
          this.$snotify.create({
            title: title,
            body: message,
            config: {
              timeout: timeOut,
              showProgressBar: true,
              closeOnClick: true,
              position: SnotifyPosition.rightTop,
              type: SnotifyStyle.error,
              buttons: [{ text: "Aceptar", action: null, bold: true }],
            },
          });
          break;
        case "warning":
          this.$snotify.create({
            title: title,
            body: message,
            config: {
              timeout: timeOut,
              showProgressBar: true,
              closeOnClick: true,
              position: SnotifyPosition.rightTop,
              type: SnotifyStyle.warning,
              buttons: [{ text: "Aceptar", action: null, bold: true }],
            },
          });
          break;
      }
    },
    logOut() {
      this.$router.push("/logout");
    },
  },
};
</script>
